<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <template slot-scope="{ attrs, listeners }">
      <b-form-radio-group
        v-bind="attrs"
        v-on="listeners"
        :disabled="disabled"
        v-model="model"
        :options="options"
        buttons
      />
    </template>
  </form-group-thux-horizontal>
</template>

<script>
export default {
  name: 'FormThuxHorizontalRadioGroupThux',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { required: true },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},

    // CHECKBOX FIELDS
    disabled: { default: false },
    options: { required: true },
    value: { required: true }
  },
  watch: {
    value () {
      this.$set(this, 'model', this.value)
    },
    model () {
      this.$emit('input', this.model)
    }
  },
  data () {
    return {
      model: false
    }
  },
  created () {
    this.$set(this, 'model', this.value)
  }
}
</script>
