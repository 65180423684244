<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <template slot-scope="{ attrs, listeners }">
      <div class="d-flex">
<!--        <div class="duration-input" v-if="years">-->
<!--          <span>{{ 'Years' | translate }}</span>-->
<!--          <b-form-input-->
<!--            v-bind="attrs"-->
<!--            v-on="listeners"-->
<!--            class="mr-3"-->
<!--            type="number"-->
<!--            :min="0"-->
<!--            v-model="model.days"-->
<!--            :disabled="disabled"-->
<!--          />-->
<!--        </div>-->
<!--        <div-->
<!--          class="duration-input"-->
<!--          :class="years ? 'pl-3': ''"-->
<!--          v-if="months">-->
<!--          <span>{{ 'Months' | translate }}</span>-->
<!--          <b-form-input-->
<!--            v-bind="attrs"-->
<!--            v-on="listeners"-->
<!--            class="mr-3"-->
<!--            type="number"-->
<!--            :min="0"-->
<!--            v-model="model.days"-->
<!--            :disabled="disabled"-->
<!--          />-->
<!--        </div>-->
        <div
          class="duration-input"
          :class="(years || months) ? 'pl-3': ''"
          v-if="days"
        >
          <span>{{ 'Days' | translate }}</span>
          <b-form-input
            v-bind="attrs"
            v-on="listeners"
            class="mr-3"
            type="number"
            :min="0"
            v-model="model.days"
            :formatter="formatInput"
            :disabled="disabled"
          />
        </div>
        <div
          class="duration-input"
          :class="(years || months || days) ? 'pl-3': ''"
          v-if="hours"
        >
          <span>{{ 'Hours' | translate }}</span>
          <b-form-input
            v-bind="attrs"
            v-on="listeners"
            type="number"
            :min="1"
            v-model="model.hours"
            :formatter="formatInput"
            :disabled="disabled"
          />
        </div>
      </div>
    </template>
  </form-group-thux-horizontal>
</template>

<script>
export default {
  name: 'form-input-thux',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { required: true },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},
    years: { default: false },
    months: { default: false },
    days: { default: true },
    hours: { default: true },

    // TEXT INPUT FIELDS
    disabled: { default: false },
    value: { required: true },
    min: { default: 0 }
  },
  watch: {
    value: {
      handler () {
        this.$set(this, 'model', this.convertStringToDuration(this.value))
      },
      deep: true
    },
    model: {
      handler () {
        this.$emit('change', this.convertDurationToString(this.model))
      },
      deep: true
    }
  },
  data () {
    return {
      model: {}
    }
  },
  created () {
    this.$set(this, 'model', this.convertStringToDuration(this.value))
  },
  methods: {
    convertStringToDuration (string) {
      if (string) {
        const numberOfHours = string.split(':')[0]
        const totalHoursList = numberOfHours.split(' ')
        let days = 0
        let hours = 0
        if (totalHoursList.length === 2) {
          days = parseInt(totalHoursList[0].trim())
          hours = parseInt(totalHoursList[1].trim())
        } else {
          days = Math.floor(parseInt(numberOfHours) / 24)
          const remainder = numberOfHours % 24
          hours = Math.floor(remainder)
        }
        return { days: days, hours: hours }
      }
      return {}
    },
    convertDurationToString (duration) {
      if (duration && (duration.days || duration.hours)) {
        const days = duration.days ? parseInt(duration.days) : 0
        const hours = duration.hours ? parseInt(duration.hours) : 0
        if (days) {
          return `${days} ${hours > 9 ? hours : `0${hours}`}:00:00`
        }
        return `${hours > 9 ? hours : `0${hours}`}:00:00`
      }
      return null
    },
    formatInput (e, min) {
      if (min || parseInt(min) === 0) {
        if (parseInt(e) <= parseInt(this.min)) {
          return null
        }
      }
      return e
    }
  }
}
</script>
