<template>
  <div
    v-if="show"
    :id="id"
    :class="[classes, disabled ? 'editor-disabled' : '']"
  >
    <div v-if="showHtml" v-html="value" class="editor-content" />
    <ckeditor
      v-if="showEditor"
      v-model="editorData"
      :editor="editor"
      :value="value"
      :config="editorConfig"
      :disabled="disabled"
      @input="change"
    />
  </div>
</template>

<script>
import hljs from 'highlight.js'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'FormThuxHorizontalCKEditorThux',
  props: {
    disabled: { default: false },
    value: { required: true },
    show: { default: true },
    showEditor: { default: true },
    showHtml: { default: false },
    id: { default: '' },
    classes: { default: '' },
    options: {}
  },
  watch: {
    value () {
      this.$set(this, 'editorData', this.value)
    }
  },
  data () {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',
            'strikethrough',
            'link',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent'
          ]
        },
        language: this.$i18n.locale()
      }
    }
  },
  created () {
    this.$set(this, 'editorData', this.value)
  },
  mounted () {
    this.$set(this, 'editorData', this.value)
    document.querySelectorAll('code').forEach((el) => {
      console.log(el)
      hljs.highlightElement(el)
    })
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  }
}
</script>
