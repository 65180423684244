<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <template slot-scope="{ attrs, listeners }">
      <b-form-textarea
        v-bind="attrs"
        v-on="listeners"
        v-model="model"
        :type="type"
        :rows="rows"
        :max-rows="maxRows"
        :disabled="disabled"
      />
    </template>
  </form-group-thux-horizontal>
</template>

<script>
export default {
  name: 'FormThuxHorizontalTextArea',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { required: true },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},

    // TEXT AREA FIELDS
    disabled: { default: false },
    value: {
      required: true
    },
    type: { default: 'text' },
    maxRows: {},
    rows: {}
  },
  watch: {
    value () {
      this.$set(this, 'model', this.value)
    },
    model () {
      this.$emit('change', this.model)
    }
  },
  data () {
    return {
      model: ''
    }
  },
  created () {
    this.$set(this, 'model', this.value)
  }
}
</script>
