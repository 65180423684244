<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <label v-if="checkValue" class="col-form-label">
      <slot name="body">
        <i v-if="iconClass" :class="iconClass" class="mr-1" />
        <span
          v-if="type === 'text'"
          :class="classSpan"
          @click="$emit('click')"
          >{{ value | translate }}</span
        >
        <span
          v-else-if="type === 'date'"
          :class="classSpan"
          @click="$emit('click')"
          >{{ value | date }}</span
        >
        <span
          v-else-if="type === 'datetime'"
          :class="classSpan"
          @click="$emit('click')"
          >{{ value | datetime }}</span
        >
        <span
          v-else-if="type === 'duration'"
          :class="classSpan"
          @click="$emit('click')"
          >{{ value | duration }}</span
        >
        <span v-else :class="classSpan" @click="$emit('click')">
          {{ value }}
        </span>
      </slot>
    </label>
    <label v-else> - </label>
  </form-group-thux-horizontal>
</template>

<script>
export default {
  name: 'FormThuxHorizontalText',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { required: true },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},
    disabled: {},

    // TEXT FIELDS
    classSpan: {},
    iconClass: {},
    type: { default: 'text' },
    value: { required: true }
  },
  computed: {
    checkValue () {
      if (
        !Array.isArray(this.value) &&
        typeof this.value !== 'undefined' &&
        this.value !== null &&
        this.value !== ''
      ) {
        return true
      }
      if (Array.isArray(this.value) && this.value.length) {
        return true
      }
      return false
    }
  }
}
</script>
