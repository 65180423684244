<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <ckeditor-thux
      v-if="show"
      :value="value"
      :disabled="disabled"
      :options="options"
      :class="classes"
      @input="change"
    />
    <slot v-else name="other-components">
      <label>{{ 'Loading...' | translate }}</label>
    </slot>
  </form-group-thux-horizontal>
</template>

<script>
import CKEditorThux from '@/components/CKEditorThux'

export default {
  name: 'FormCKEditorThux',
  components: { 'ckeditor-thux': CKEditorThux },
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { required: true },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},

    // DATE PICKER FIELDS
    disabled: { default: false },
    value: { required: true },
    show: { default: true },
    classes: { default: '' },
    options: {}
  },
  watch: {
    value () {
      this.$set(this, 'editorModel', this.value)
    }
  },
  data () {
    return {
      editorModel: ''
    }
  },
  created () {
    this.$set(this, 'editorModel', this.value)
  },
  methods: {
    change (value) {
      this.$emit('change', value)
    }
  }
}
</script>
