<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <template slot-scope="{ attrs, listeners }">
      <b-form-checkbox-group
        v-bind="attrs"
        v-on="listeners"
        v-model="model"
        :options="options"
        :disabled="disabled"
      />
    </template>
  </form-group-thux-horizontal>
</template>

<script>
export default {
  name: 'FormThuxHorizontalCheckboxGroup',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { default: '' },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},

    // CHECKBOX FIELDS
    disabled: { default: false },
    value: { required: true },
    options: { default: [] }
  },
  watch: {
    value () {
      this.$set(this, 'model', this.value)
    },
    model () {
      this.$emit('change', this.model)
    }
  },
  data () {
    return {
      model: []
    }
  },
  created () {
    this.$set(this, 'model', this.value)
  }
}
</script>
