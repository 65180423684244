<script>
import PopulateSelectMixin from '../PopulateSelectMixin'
import FormHorizontalAutocompleteThux from '../form-thux-horizontal-components/FormThuxHorizontalAutocomplete'
import FormThuxHorizontalCheckbox from '../form-thux-horizontal-components/FormThuxHorizontalCheckbox'
import FormThuxHorizontalDatetimePicker from '../form-thux-horizontal-components/FormThuxHorizontalDatetimePicker'
import FormThuxHorizontalFileThux from '../form-thux-horizontal-components/FormThuxHorizontalFileThux'
import FormThuxHorizontalInput from '../form-thux-horizontal-components/FormThuxHorizontalInput'
import FormThuxHorizontalMultiselect from '../form-thux-horizontal-components/FormThuxHorizontalMultiselect'
import FormThuxHorizontalRadioGroup from '../form-thux-horizontal-components/FormThuxHorizontalRadioGroup'
import FormThuxHorizontalSelect from '../form-thux-horizontal-components/FormThuxHorizontalSelect'
import FormThuxHorizontalText from '../form-thux-horizontal-components/FormThuxHorizontalText'
import FormThuxHorizontalTextArea from '../form-thux-horizontal-components/FormThuxHorizontalTextArea'
import FormThuxHorizontalCheckboxGroup from '../form-thux-horizontal-components/FormThuxHorizontalCheckboxGroup'
import FormThuxHorizontalDuration from '../form-thux-horizontal-components/FormThuxHorizontalDuration'
import FormThuxHorizontalCKEditor from '../form-thux-horizontal-components/FormThuxHorizontalCKEditor'

export default {
  name: 'ThuxDetailPanelMixin',
  props: ['id', 'editParams'],
  mixins: [PopulateSelectMixin],
  components: {
    'form-thux-horizontal-autocomplete': FormHorizontalAutocompleteThux,
    'form-thux-horizontal-checkbox': FormThuxHorizontalCheckbox,
    'form-thux-horizontal-datetime': FormThuxHorizontalDatetimePicker,
    'form-thux-horizontal-file': FormThuxHorizontalFileThux,
    'form-thux-horizontal-input': FormThuxHorizontalInput,
    'form-thux-horizontal-multiselect': FormThuxHorizontalMultiselect,
    'form-thux-horizontal-radio-group': FormThuxHorizontalRadioGroup,
    'form-thux-horizontal-select': FormThuxHorizontalSelect,
    'form-thux-horizontal-text': FormThuxHorizontalText,
    'form-thux-horizontal-text-area': FormThuxHorizontalTextArea,
    'form-thux-horizontal-checkbox-group': FormThuxHorizontalCheckboxGroup,
    'form-thux-horizontal-duration': FormThuxHorizontalDuration,
    'form-thux-horizontal-ckeditor': FormThuxHorizontalCKEditor
  },
  watch: {
    id () {
      this.$set(this, 'form', {})
      this.init()
    }
  },
  data () {
    return {
      form: {},
      formEditable: false,
      pageName: ''
    }
  },
  created () {
    this.init()
  },
  computed: {
    title: function () {
      if (this.id) {
        if (this.formEditable) {
          return `${this.$t('Edit')} ${this.$t(this.pageName).toLowerCase()}`
        } else {
          return `${this.$t('Show')} ${this.$t(this.pageName).toLowerCase()}`
        }
      } else {
        return `${this.$t('Add')} ${this.$t(this.pageName).toLowerCase()}`
      }
    }
  },
  methods: {
    initFields () {},
    init () {
      this.cleanErrors()
      this.initFields()
      if (this.id) {
        this.retrieve({ id: this.id }).then(
          () => {
            this.makeFormReadonly()
            this.setForm()
          }
        )
      } else {
        this.makeFormEditable()
      }
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      this.$set(this, 'form', form)
    },
    getIds (listName, fieldName) {
      let list = this.form[listName]
      if (list && list.length > 0) {
        list = list.filter((instance) => {
          if (typeof instance === 'object') return Object.keys(instance).length > 0
          return true
        })
        return list.map((instance) => {
          if (instance[fieldName]) {
            return instance[fieldName]
          }
          return instance
        })
      }
      return []
    },
    dateChange (evt, fieldName, format = 'YYYY-MM-DD') {
      const date = evt.date
      if (date) {
        this.$set(this.form, fieldName, date.format(format))
      } else {
        this.$set(this.form, fieldName, undefined)
      }
    },
    getFormData () {
      return Object.assign({}, this.form)
    },
    onSubmit () {
      const formData = this.getFormData()
      const promise = this.id ? this.update(formData) : this.create(formData)
      promise.then(
        () => {
          setTimeout(() => {
            if (!this.id) {
              this.closeForm()
            } else {
              this.makeFormReadonly()
            }
          }, 500)
        }
      )
    },
    cancel () {
      if (!this.id) {
        this.closeForm()
      } else {
        this.setForm()
        this.makeFormReadonly()
      }
    },
    closeForm () {
      this.$emit('close-form')
    },
    makeFormEditable () {
      this.$set(this, 'formEditable', true)
    },
    makeFormReadonly () {
      this.$set(this, 'formEditable', false)
    }
  }
}
</script>
