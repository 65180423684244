<template>
  <form-group-thux-horizontal
    :validator="validator"
    :custom-message-validator="customMessageValidator"
    :is-dirty="isDirty"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <div :class="unit ? 'prepend-input' : ''">
      <slot name="placeholder">
        <span>{{ unit }}</span>
      </slot>
      <multiselect-thux
        v-if="showAutocomplete"
        :class="getBorderClass"
        :open-direction="openDirection"
        :disabled="disabled"
        :label="labelSearch"
        attributetype="autocomplete"
        :value="value"
        :options="options"
        :placeholder="placeholderField ? placeholderField : placeholder"
        :customize-option="customizeOption"
        :customize-label="customizeLabel"
        :hide-selected="false"
        @search="search"
        @select="select"
        @clear="clear"
      >
        <template v-if="customizeOption" slot="option" slot-scope="data">
          <div class="custom-option" v-html="getCustomOption(data)"></div>
        </template>
        <template v-if="customizeLabel" slot="singleLabel" slot-scope="data">
          <div class="custom-option" v-html="getCustomLabel(data)"></div>
        </template>
      </multiselect-thux>
      <slot v-else name="other-components"></slot>
    </div>
  </form-group-thux-horizontal>
</template>

<script>
import MultiselectThux from '@/components/MultiselectThux'

export default {
  name: 'FormHorizontalAutocompleteThux',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { required: true },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},

    // AUTOCOMPLETE FIELDS
    showAutocomplete: { default: true },
    openDirection: { default: 'bottom' },
    customizeOption: { default: false },
    customizeLabel: { default: false },
    disabled: { default: false },
    labelSearch: { required: true },
    value: { required: true },
    options: { required: true },
    unit: {},
    placeholder: { default: '' },
    placeholderField: {}
  },
  components: {
    'multiselect-thux': MultiselectThux
  },
  data () {
    return {
      isInitValue: true,
      customMessageValidator: undefined,
      isDirty: false
    }
  },
  computed: {
    getBorderClass () {
      if (!this.isInitValue) {
        if (this.validator && this.validator.$invalid) {
          if (!this.value) {
            this.$set(this, 'customMessageValidator', `${this.labelForm} ${this.$t('is required')}`)
          } else {
            this.$set(this, 'customMessageValidator', `${this.labelForm}: ${this.$t('value not permitted')}`)
          }
          this.$set(this, 'isDirty', true)
          return 'multiselect_with_border_red'
        } else if (this.validator && !this.validator.$invalid && this.value) {
          this.$set(this, 'customMessageValidator', null)
          this.$set(this, 'isDirty', false)
          return 'multiselect_with_border_active'
        }
      }
      this.$set(this, 'customMessageValidator', null)
      return 'multiselect_with_border'
    }
  },
  methods: {
    search (value) {
      this.$emit('search', value)
    },
    select (value) {
      this.$set(this, 'isInitValue', false)
      this.$emit('select', value)
    },
    clear () {
      this.$set(this, 'isInitValue', false)
      this.$emit('clear')
    },
    getCustomOption (data) {
      const option = data.item.option
      let statusIcon = ''
      let optionClasses = ''
      if (option.status === 0) {
        statusIcon = '<i class="far fa-times-circle text-red mr-2"></i>'
        optionClasses = 'option-disabled'
      }
      return `${statusIcon} <span class="${optionClasses}">${option[this.labelSearch]}</span>`
    },
    getCustomLabel (data) {
      const option = data.item.option
      let statusIcon = ''
      let optionClasses = ''
      if (option.status === 0) {
        statusIcon = '<i class="far fa-times-circle text-red mr-2"></i>'
        optionClasses = 'option-disabled'
      }
      return `${statusIcon} <span class="${optionClasses}">${option[this.labelSearch]}</span>`
    }
  }
}
</script>
