<template>
  <form-group-thux-horizontal
    :validator="validator"
    :label="labelForm | translate"
    :label-class="labelFormClass"
    :label-cols-sm="labelColsSm"
    :label-cols-lg="labelColsLg"
    :class="classForm"
    :external-errors="externalErrors"
    :disabled="disabled"
  >
    <multiselect-thux
      v-if="showSelect"
      :class="getBorderClass"
      :disabled="disabled"
      :label="labelSearch"
      :customizeOption="customizeOption"
      attributetype="select"
      :value="value"
      :options="options"
      :placeholder="$t(placeholder)"
      @select="select"
      @clear="clear"
    >
      <template v-if="customizeOption" slot="option" slot-scope="data">
        <div class="custom-option" v-html="getCustomOption(data)"></div>
      </template>
    </multiselect-thux>
    <slot v-else name="other-components"></slot>
  </form-group-thux-horizontal>
</template>

<script>
import MultiselectThux from '@/components/MultiselectThux'

export default {
  name: 'form-select-thux',
  props: {
    // FORM FIELDS
    validator: {},
    labelForm: { required: true },
    labelColsSm: { default: 2 },
    labelColsLg: { default: 2 },
    labelFormClass: {},
    classForm: {},
    externalErrors: {},

    // SELECT FIELDS
    showSelect: { default: true },
    placeholder: { default: 'Select an option' },
    customizeOption: { default: false },
    disabled: { default: false },
    labelSearch: { required: true },
    value: { required: true },
    options: { required: true },
    optionValue: { default: 'name' }
  },
  components: {
    'multiselect-thux': MultiselectThux
  },
  data () {
    return {
      isInitValue: true
    }
  },
  computed: {
    getBorderClass () {
      if (!this.isInitValue) {
        if (this.validator && this.validator.$invalid && !this.value) {
          return 'multiselect_with_border_red'
        } else if (this.validator && !this.validator.$invalid && this.value) {
          return 'multiselect_with_border_active'
        } else {
          return 'multiselect_with_border'
        }
      }
      return 'multiselect_with_border'
    }
  },
  methods: {
    select (value) {
      this.$set(this, 'isInitValue', false)
      this.$emit('select', value)
    },
    clear () {
      this.$set(this, 'isInitValue', false)
      this.$emit('clear')
    },
    getCustomOption (data) {
      const option = data.item.option
      let statusIcon = ''
      if (option.status === 0) {
        statusIcon = '<i class="fas fa-times-circle text-red mr-2"></i>'
      }
      return `${statusIcon} <span>${option[this.optionValue]}</span>`
    }
  }
}
</script>
